var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"mask"},[_c('div',{ref:"qrCodeUrl",attrs:{"id":"qrcode"}})]),_vm._m(0),_c('div',{staticClass:"timePicker"},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","value-format":"yyyy-MM-dd"},model:{value:(_vm.timeDate),callback:function ($$v) {_vm.timeDate=$$v},expression:"timeDate"}}),_c('div',{staticClass:"findBtn"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.find()}}},[_vm._v("查询")])],1)],1),_c('div',{staticClass:"tableData"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"stripe":"","header-cell-style":{
        background: '#189f9240',
        textAlign: 'center',
        color: '#333333',
        fontWeight: 'normal',
      },"cell-style":{
        textAlign: 'center',
      }}},[_c('el-table-column',{attrs:{"width":"70","prop":"idx","label":"序号"}}),_c('el-table-column',{attrs:{"prop":"name","label":"姓名","width":"120"}}),_c('el-table-column',{attrs:{"prop":"sex","label":"性别","width":"100"}}),_c('el-table-column',{attrs:{"prop":"number","label":"编号","width":"100"}}),_c('el-table-column',{attrs:{"prop":"unit","label":"体检单位"}}),_c('el-table-column',{attrs:{"prop":"dataTime","label":"体检日期","width":"190"}}),_c('el-table-column',{attrs:{"prop":"category","label":"人员类别","width":"120"}}),_c('el-table-column',{attrs:{"prop":"timeout","label":"健康证是否过期","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.timeout)?_c('span',[_vm._v("已过期")]):_c('span',[_vm._v("未过期")])]}}])}),_c('el-table-column',{attrs:{"prop":"id","label":"操作","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"btnBox"},[_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.deleteData(scope.row.id, scope.row.name)}}},[_vm._v("删除")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.downData(scope.row.id, scope.row.name)}}},[_vm._v("下载")])],1)]}}])})],1),_c('div',{staticClass:"fy"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-sizes":[50, 100, 150, 200, 250],"page-size":10,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v("查看人员信息")])])
}]

export { render, staticRenderFns }